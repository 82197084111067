<template>
  <v-container class="px-3 py-0" fluid>
    <v-row>
      <v-col cols="12" class="mt-sm-6">
        <v-card class="mx-auto" elevation="0">
          <base-material-card
            color="primary"
            width="2600"
            flat
            :icon="header.icon"
            :title="header.title"
          >
            <router-view></router-view>
          </base-material-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { eventBus } from "@/main";

export default {
  name: "Crud",
  data() {
    return {
      header: { icon: undefined, title: undefined },
    };
  },
  computed: {
    routeInfo() {
      switch (this.$route.name) {
        case "Fazendas":
          return { icon: "mdi-barn", title: "Propriedades" };
        case "Talhão":
          return { icon: "border_all", title: "Talhão" };
        case "Receitas":
          return { icon: "attach_money", title: "Receitas" };
        case "Despesas":
          return { icon: "money_off", title: "Despesas" };
        case "Fretes":
          return { icon: "mdi-truck", title: "Fretes" };
        case "Previsão Orçamentária":
          return {
            icon: "mdi-calculator",
            title: "Previsão Orçamentária da Safra",
          };
        case "Lote":
          return { icon: "mdi-cow", title: "Lote Boi Gordo" };
        default:
          return { icon: "mdi-help", title: "Desconhecido" };
      }
    },
  },
  watch: {
    routeInfo(val) {
      this.header = val;
    },
  },
  created() {
    this.header = this.routeInfo;
    eventBus.$on("change-budget-POP", () => {
      this.header = {
        icon: "mdi-calculator",
        title: "Previsão Orçamentária da Pecuária",
      };
    });
    eventBus.$on("change-budget-POS", () => {
      this.header = {
        icon: "mdi-calculator",
        title: "Previsão Orçamentária da Safra",
      };
    });
  },
};
</script>

<style>
</style>